import React from "react";
// @ts-expect-error
import { ApmRoutes, withTransaction } from "@elastic/apm-rum-react";
import { BrowserRouter, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./App.css";
import { Nav } from "./components/Nav";
import { ImageHeader } from "./components/ImageHeader";
import { Loading } from "./components/Loading";
import { URLS } from "./constants";
import i18next from "i18next";

// loading component for suspense fallback
const Loader = () => (
  <div className="App-loading">
    <Loading height={100} />
    <div>loading...</div>
  </div>
);

function AppView() {
  // load the translation files
  useTranslation();

  return (
    <ApmRoutes>
      {URLS.map(({ href, Component, title }, index) => {
        const ApmComponent = withTransaction(
          title,
          "component"
        )(() => (
          <React.Fragment>
            <ImageHeader />
            <Nav />
            <div className="App">
              <Component data-testid="app-component" />
            </div>
          </React.Fragment>
        ));

        return (
          <Route
            key={index}
            path={href}
            index={href === "/"}
            element={<ApmComponent />}
          />
        );
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </ApmRoutes>
  );
}

class App extends React.Component {
  public componentDidMount() {
    // inject the i18n identified language into the "html lang" property
    const htmlEl = document.getElementsByTagName("html");
    htmlEl[0].setAttribute("lang", i18next.language);
  }

  public render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <AppView />
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
