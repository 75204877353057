import type { es } from "./spanish";
export const en: typeof es = {
  // export const en = {
  translation: {
    header: {
      "nos-casamos": "We are getting married!",
      date: "4 June 2022",
      only: "Only",
      still: "Still",
      dias: "days",
    },
    navigation: {
      "¡Bienvenido!": "¡Hola!",
      details: "The Big Day",
      "Confirma tu asistencia": "Confirm you attendance",
    },
    welcome: {
      title: "¡Hola!",
      monthsLeft_one: "only one month",
      monthsLeft_other: "only {{count}} months",
      daysLeft_zero: "only hours",
      daysLeft_other: "only {{count}} days",
      timeLeft: " ({{unitOfTimeLeft}} to go 😱)",
      body: `
<p>
So... here we go!
</p><p>
After 14 years together, having lived in 3 different cities, a pandemic (this is a piece of cake if compare it to the rest :p), a son, a "dog-ther", and the daily joy of life... we are getting married!
</p><p>
And you may say... "oh well, nothing new, I already knew it". And you would be correct! But here's the actual news: we are going to celebrate it! Being in the middle of a pandemic (even it it isn't over yet) and 8 months pregnant... it just wasn't the right moment to have our party... so we chose to press the pause button in 2021.
</p><p>
But the <strong>4th June 2022</strong> will be the day! And, of course, we need you to celebrate it the way it deserves.
</p><p>
While the big day arrives{{timeLeft}}, we created this website to keep you posted.
</p><p>
Click <0><strong>here</strong></0> to add the event to your calendar with a few details, like the time and the address of the venue.
</p><p>
And that’s it from us!
We'll leave you on your search of the perfect matching colours for your dress/tie and face mask.
We hope you enjoy the website and we are looking forward to seeing you in Granada.
</p>
      `,
    },
    details: {
      location: "Where and when?",
      time: "Starting with...",
      "time-description": `
<p>
... the ceremony at <strong>1pm</strong>.
</p>
<p>
Please, arrive a bit earlier to help on easing the groom's wait.<br />
Doors will open at <strong>12:30pm</strong>. We'll be serving some lemonades to help on beating <i>la caló</i> (the Spanish Southern heat).
</p>
      `,
      address: "Address",
    },
  },
};
