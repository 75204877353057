import React, { Component } from "react";
import styled from "styled-components";

interface Props {
  height: number;
}

const Image = styled.img`
  vertical-align: -webkit-baseline-middle;
`;

const LoadingDiv = styled.div`
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
`;

export class Loading extends Component<Props> {
  public render() {
    return (
      <LoadingDiv>
        <Image src="./loading.gif" height={this.props.height} alt="loading" />
      </LoadingDiv>
    );
  }
}
