export const es = {
  translation: {
    header: {
      "nos-casamos": "¡Nos casamos!",
      date: "4 junio 2022",
      only: "Solo",
      still: "Quedan",
      dias: "días",
    },
    navigation: {
      "¡Bienvenido!": "¡Hola!",
      details: "El Gran Día",
      "Confirma tu asistencia": "Confirma tu asistencia",
    },
    welcome: {
      title: "¡Hola a todos!",
      monthsLeft_one: "queda un mes",
      monthsLeft_other: "quedan {{count}} meses",
      daysLeft_zero: "quedan horas",
      daysLeft_other: "quedan {{count}} días",
      timeLeft: "(¡exacto! ¡sólo {{unitOfTimeLeft}}! nervios... nervios...)",
      body: `
<p>
Parecía que no iba a llegar este momento pero ¡aquí estamos!
</p><p>
Después de los (espera, voy a hacer la cuenta: de 2008 a 2022 son...) 14 años que hace que empezamos a salir, varias ciudades, una pandemia, un hijo y una perrhija e infinidad de capítulos vitales diversos... ¡nos casamos!
</p><p>
Y bueno, diréis, "ya lo sabía". La novedad es que ¡ahora lo vamos a poder celebrar! La pandemia primero y la llegada al mundo de Alejandrito hicieron que 2021 no fuera el año para poder celebrar de la forma que se merece con todos vosotros.
</p><p>
¡Ha llegado el momento de poder comer, beber y bailar juntos pero al aire libre y con medidas de seguridad!
</p><p>
Por eso nos gustaría que os reservéis el <strong>4 de Junio de 2022</strong> {{timeLeft}} porque no hay mejor regalo que teneros allí ese día. La boda será a medio día (12:30-13:00) y os confirmaremos la hora en breve. Será en Granada. En la Finca La Marquesa, ¡que esperamos que os encante tanto como a nosotros!
</p><p>
Mientras llega el día y "Covid mediante" hemos creado esta web para manteneros al tanto de todo, informaros de los detalles del día y que nos confirméis vuestra asistencia.
</p><p>
<0><strong>Aquí</strong></0> podéis añadir desde ya un recordatorio a vuestro calendario con los detalles de la hora y el sitio.
</p><p>
Por ahora os dejamos eligiendo el color de la Pamela… o de la corbata... Aquí estamos para cualquier cosa que nos queráis decir.
</p><p>
¡Un beso enorme!
</p>
    `,
    },
    details: {
      location: "¿Dónde y cuándo?",
      time: "Empezamos...",
      "time-description": `
<p>
... con la ceremonia que, si la novia no se hace mucho de rogar, comenzará a las <strong>13:00</strong>.
</p>
<p>
Se pide llegar un poco antes para que vayáis calmando los nervios del novio.<br />
¡A partir de las <strong>12:30</strong> sois bienvenidos la finca!
</p>
`,
      address: "Dirección",
    },
  },
};
