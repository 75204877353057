import React, { Component } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { URLS } from "../constants";

const NavBar = styled.ul`
  position: sticky;
  top: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(221, 221, 221);
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 90;

  li {
    display: inline-block;
    a {
      display: block;
      color: rgb(34, 34, 34);
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      span {
        transition: border-bottom-color 0.2s;
        border-bottom: 1px solid transparent;
        padding-bottom: 3px;
        white-space: normal;
      }
      :hover span {
        border-bottom-color: rgba(0, 0, 0, 0.8);
      }
    }
    a.active span {
      border-bottom-color: rgba(0, 0, 0, 0.8);
    }
  }
`;

export class Nav extends Component {
  private urls = URLS;

  render() {
    return (
      <NavBar id="nav">
        {this.urls.map(({ href, title }, index) => {
          return (
            <li key={title}>
              <Link
                key={index}
                to={href}
                className={window.location.pathname === href ? "active" : ""}
              >
                <span>
                  <Trans i18nKey={`navigation.${title}`}>{title}</Trans>
                </span>
              </Link>
            </li>
          );
        })}
      </NavBar>
    );
  }
}
