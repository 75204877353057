import "./apm";
import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import { weddingName } from "./constants";

ReactDOM.render(<App />, document.getElementById("root"));

document.title = weddingName();
