import React, { Component } from "react";
import moment from "moment";
import styled from "styled-components";
import { Trans } from "react-i18next";
import { weddingName, WEDDING_DATE } from "../constants";

const isHome = () => window.location.pathname === "/";

const DivWithBackgroundImage = styled.div`
  background: ${() =>
    isHome()
      ? "url(/i-do.jpg) no-repeat center #f0f0f0"
      : "url(/finca-la-marquesa.jpg) no-repeat center 0% #f0f0f0;"};
  background-size: cover;
  filter: ${() => (isHome() ? "saturate(20%)" : "saturate(80%)")};
  padding: 0;
  margin: 0;
  height: 70vh;
  width: 100vw;
  text-align: center;
  font-family: "American Typewriter";
  color: #343434;
  @media only screen and (max-width: 600px), (max-height: 600px) {
    height: ${() => (isHome() ? null : "50vh")};
  }
`;

const Wrapper = styled.div`
  min-width: 200px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 600px), (max-height: 600px) {
    font-size: 0.9em;
  }
  .big-font {
    margin: 7% 0;
    display: block;
    font-size: 2em !important;
    font-weight: 400;
    line-height: 1em !important;
    @media only screen and (min-width: 600px) and (min-height: 600px) {
      margin: 2% 0;
    }
  }
`;

const AnnounceFlag = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.65) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  margin: 0;
  @media only screen and (min-width: 600px) and (min-height: 600px) {
    font-size: 1.3em !important;
  }
`;

const TextBlock = styled.p`
  padding: 5% 10% 20%;
  margin: 0;
  display: block;
  @media only screen and (max-width: 600px), (max-height: 600px) {
    padding-top: 10%;
  }
`;

const CountDown = styled.p`
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 27px 0;
  height: 100px;
  width: 100px;
  margin: 0;
  right: 10px;
  bottom: 0px;
  line-height: 0.1em;
  text-align: center;
  position: absolute;
  span {
    margin: 7% 0 !important;
    font-family: ProximaNova, Helvetica, Arial, sans-serif;
  }
  @media only screen and (max-width: 600px), (max-height: 600px) {
    height: 90px;
    width: 90px;
  }
`;

const LazyImage = styled.img`
  display: block;
  position: absolute;
  top: 0px;
  width: 0px;
  height: 0px;
`;

export function preloadImages() {
  const images = ["/i-do.jpg", "/finca-la-marquesa.jpg"];

  return (
    <React.Fragment>
      {images.map((img) => (
        <LazyImage key={img} src={img} alt="preloading" loading="lazy" />
      ))}
    </React.Fragment>
  );
}

export class ImageHeader extends Component {
  render() {
    const diff = Math.ceil(moment(WEDDING_DATE).diff(moment(), "days", true));
    return (
      <React.Fragment>
        <DivWithBackgroundImage data-testid="header-background-image">
          <Wrapper>
            <AnnounceFlag>
              <TextBlock>
                <span>
                  <Trans i18nKey="header.nos-casamos">¡Nos casamos!</Trans>
                </span>
                <span className="big-font">{weddingName()}</span>
                <span>
                  <Trans i18nKey="header.date">4 junio 2022</Trans>
                </span>
              </TextBlock>
            </AnnounceFlag>
            {diff > 0 ? (
              <CountDown>
                {diff < 30 ? (
                  <Trans i18nKey="header.only">Solo</Trans>
                ) : (
                  <Trans i18nKey="header.still">Quedan</Trans>
                )}
                <span className="big-font">{diff}</span>
                <Trans i18nKey="header.dias">días</Trans>
              </CountDown>
            ) : (
              ""
            )}
          </Wrapper>
        </DivWithBackgroundImage>
        {preloadImages()}
      </React.Fragment>
    );
  }
}
