import React from "react";

interface IURLS {
  href: string;
  title: string;
  Component: React.ExoticComponent;
}

/**
 * List of URLs in the website with the expected view to load
 */
export const URLS: IURLS[] = [
  {
    href: "/",
    title: "¡Bienvenido!",
    Component: React.lazy(() => import("./views/Home")),
  },
  {
    href: "/details",
    title: "details",
    Component: React.lazy(() => import("./views/Details")),
  },
];

export function weddingName() {
  return window.location.host === "alejandroymarta.es"
    ? "Alejandro & Marta"
    : "Marta & Alejandro";
}

export const WEDDING_DATE = "2022-06-04T13:00:00.000+0200";

export const MAP_URLS = {
  english:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3180.5505329498533!2d-3.5921676847005117!3d37.13960697988003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd71fb48eb84de2b%3A0x2efee16b9c686b9c!2sFinca%20La%20Marquesa!5e0!3m2!1sen!2ses!4v1642716083702!5m2!1sen!2ses",
  spanish:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3180.550532949845!2d-3.5921676843102315!3d37.13960697988004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd71fb48eb84de2b%3A0x2efee16b9c686b9c!2sFinca%20La%20Marquesa!5e0!3m2!1ses!2ses!4v1642715984384!5m2!1ses!2ses",
};
