import { init as initApm } from "@elastic/apm-rum";
import pkg from "../package.json";

function getLoadTraceId() {
  const localTraceId =
    window.localStorage.getItem("apm:localTraceId") ||
    `${Date.now()}-${Math.random()}`;
  window.localStorage.setItem("apm:localTraceId", localTraceId);
  return localTraceId;
}

const apm = initApm({
  // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
  serviceName: "boda-web",

  // Set custom APM Server URL (default: http://localhost:8200)
  serverUrl:
    "https://c7e3d866d1cb45ddae471684bd5a43fd.apm.europe-west1.gcp.cloud.es.io:443",

  // Set service version (required for source map feature)
  serviceVersion: pkg.version,

  environment: process.env.REACT_APP_APM_ENVIRONMENT,

  pageLoadTraceId: getLoadTraceId(),
});

export default apm;
